<template>
  <div class="container">
    <div class="main-content">
      <div class="row">
        <div class="col h3">Thông tin về HANSAN</div>
      </div>
      <div class="row mt-2"><div class="col"><a class="text-primary" href="http://hansan.vn/dang-ky">HANSAN</a> là công ty công nghệ trẻ, năng động, chuyên cung cấp giải pháp công nghệ tốt nhất thị trường hiện nay, với phương châm công ty là mang lại lợi ích và sự dể dàng nhất cho công ty</div></div>
      <div class="row"><div class="col">Chúng tôi là những người đam mê công nghệ và có kinh nghiệm trong lĩnh vực thiết kế và phát triển phần mềm trên nền tảng đám mây</div></div>
      <div class="row"><div class="col">Để đáp lại cho sự phát triển nhanh chóng của hệ thống công nghệ thông tin trên thế giới và trong nước, <a class="text-primary" href="http://hansan.vn/dang-ky">HANSAN</a> luôn cập nhật và tối ưu chức năng để dể dàng cho khách hàng trong việc thao tác, lưu trữ và sử dụng trong công việc</div></div>
      <div class="row">
        <div class="col h3">{{$t('common.product_list')}}</div>
      </div>
      <div class="row"><div class="col">Chúng tôi cung cấp những phần mềm tiện lợi nhất và giá cả hợp lý nhất về hệ thống quản lý chuổi cửa hàng như:</div></div>
      <ul>
        <li class="text-primary">Hệ thống quản lý chuổi những cơ sở kinh doanh Lô Tô vé số tự chọn</li>
        <li class="text-primary">Hệ thống quản lý chuổi cửa hàng kinh doanh quán cafe</li>
        <li class="text-primary">Hệ thống quản lý chuổi cửa hàng kinh doanh phòng karaoke</li>
        <li class="text-primary">Hệ thống quản lý chuổi cửa hàng kinh doanh quán ăn</li>
        <li class="text-primary">Hệ thống quản lý chuổi cửa hàng kinh doanh bàn bida</li>
        <li class="text-primary">Hệ thống quản lý chuổi những cơ sở kinh doanh hotel, khách sạn</li>
        <li class="text-primary">Hệ thống quản lý chuổi những cơ sở kinh doanh cầm đồ</li>
        <li class="text-primary">Hệ thống quản lý chuổi những cơ sở nha khoa (phòng khám răng)</li>
        <li class="text-primary">Hệ thống quản lý chuổi những cơ sở sửa chữa xe oto, gắn máy</li>
        <li class="text-primary">Hệ thống quản lý chuổi những cơ sở kinh doanh phòng Spa, viện mỹ phẩm</li>
        <li class="text-primary">Hệ thống quản lý chuổi những sân bóng đá mini</li>
        <li class="text-primary">Hệ thống quản lý chuổi những cơ sở kinh doanh Vật liệu xây dựng</li>
        <li class="text-primary">Hệ thống quản lý Nhân sự chấm công tính lương</li>
        <li class="text-primary">Hệ thống quản lý chuổi những cơ sở kinh doanh nhà thuốc tây</li>
        <li class="text-primary">Hệ thống quản lý chuỗi các tiệm thuốc tây</li>
        <li class="text-primary">Hệ thống quản lý chuỗi các cơ sở kinh doanh tole, sắt, thép</li>
        <li class="text-primary">Hệ thống quản lý chuỗi các cơ sở kinh doanh beer, nước giải khát</li>
      </ul>
      <div class="row">
        <div class="col">
          <div class="h3">Tại sao bạn chọn hệ thống phần mềm ở HANSAN</div>
          <ul><li>Hệ thống Website tối ưu việc tìm kiếm trên google, vì chúng tôi sử dụng công cụ, nền tảng tới ưu truy cập trên hệ thống điện thoại</li>
          <li>Đội ngũ hổ trợ nhiệt tình chu đáo, có kinh nghiệm chuyên môn cao về nền tảng công nghệ mới, công nghệ lưu trữ đám mây</li>
          <li>Hệ thống website chạy được trên tất cả thiết bị, dễ dàng truy cập và làm việc mọi lúc mọi nơi</li>
          <li>Cài đặt đơn giản, hệ thống được thiết kế hiện đại dể thao tác, sửa dụng.</li>
          <li>Hệ thống quản lý 1 hay nhiều cơ sở cùng lúc, cho phép admin quản trị cùng lúc nhiều cơ sở chi nhánh</li>
          <li>Giá cả hợp lý, chỉ 50k/user/tháng</li></ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="h3 text-info">Mục tiêu</div>
          <div class="text-muted">Trở thành nhà cung cấp phần mềm uy tín và chất lượng trong và ngoài nước
          xây dựng đội ngũ kỹ sư công nghệ giàu nhiệt huyết và chất lượng</div>
        </div>
        <div class="col-md-3">
          <div class="h3 text-info">Phương châm</div>
          <div class="text-muted">Lấy chất lượng sản phẩm và dịch vụ làm đầu, sự tin tưởng của khách hàng là sức mạnh để chúng tôi phát triển</div>
        </div>
        <div class="col-md-3">
          <div class="h3 text-info">Công nghệ</div>
          <div class="text-muted">[Python flask api, vue js, vuex, linux system, gunicorn, mongodb, nginx web server....].
          Sử dụng công nghệ tối ưu nhất và cập nhật thường xuyên, phát triển ứng dụng trên nền tảng đám mây, truy cập mọi nơi, mọi thiết bị, đảm bảo dữ liệu hoàn toàn bảo mật</div>
        </div>
        <div class="col-md-3">
          <div class="h3 text-info">Hoạt động</div>
          <div class="text-muted">Liên hệ lấy yêu cầu khách hàng, Tư vấn giải pháp công nghệ, demo sản phẩm, báo giá, triển khai hệ thống, bảo hành và bảo trì 24/24</div>
        </div>
      </div>
      <div class="row border border-light mb-1">
        <div class="col fb-comments"
        data-numposts="5" data-width="100%" data-lazy="true"></div>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
import { VueAgile } from 'vue-agile'
export default {
  name: 'Gioithieu',
}
</script>
